import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import Loading from './components/Loading';
import Table from './components/Table';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import Button from './components/Button';
import TabItems from './components/TabItems';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import Modal from './components/Modal';
import Pagination from './components/Pagination';
import { Navigate } from "react-router-dom";
import category_img from './media/category.png';
import { getAuth } from "firebase/auth";
class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      isLoaded: false,
      isLoadedSubmitCategory: false,
      categories: [],
      totalPages: null,
      currentPage: 0,
      categoryName: '',
      modalAddCategory: false,
      modalEditCategory: false,
      modalRemoveCategory: false,
      categoryNewName: '',
      categoryRowIndex: null,
      removeIndex: null,
      successSubmit: false,
      errorSubmit: null,
      resultText: '',
      modalRemoveCategoryLoading: false,
      getCategoryID: null,
      companyID: null,
      errors: null,
      error: false,
      modalDeleteCategory: false,
      idDeleteCategory: null,
      totalItemsCategories: null,
      totalItemsShops: null,
      modalSubmitStatus: false,
      categoryNameModal: null,
      editCategoryObj: null,
      errorNameOnlySpace: false,
      errorLengthCategory: false
    }
  }

  /* Handler for get mobile menu */
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };

  /* Handler for dropdown */
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }

  /* Handler get data mount component */
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
        fetch(`${that.props.queryLink}/api/v1/products/categories/search`, {
          method: 'POST',
          headers: that.props.queryHeaders(idToken),
          body: JSON.stringify({
            "itemsPerPage": 20,
            "orderField": "creationDate",
            "direction": 'DESC'
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoaded: true,
                errors: data.errors,
                error: true
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoaded: true,
                categories: data.data,
                totalPages: data.totalPages,
                totalItemsCategories: data.totalItems
              });
            })
            fetch(`${that.props.queryLink}/api/v1/shops/search`, {
              method: 'POST',
              headers: that.props.queryHeaders(idToken),
              body: JSON.stringify({
                "itemsPerPage": 20,
                "orderField": "creationDate",
                "direction": 'DESC'
              })
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  });
                })
              } else {
                res.json().then(function(data) {
                  that.setState({
                    totalItemsShops: data.totalItems
                  });
                })
              }
          })
          }
      })
    })
  }

  /* Restore modal data */
  handlerRestoreModalData(event){
    setTimeout(() => {
      this.setState({
        modalEditCategory: false,
        modalAddCategory: false,
        modalDeleteCategory: false,
        modalSubmitStatus: false,
        categoryNewName: '',
        removeIndex: null,
        idDeleteCategory: null,
        categoryName: null,
        editCategoryObj: null,
        errorNameOnlySpace: false,
        errorCategoryName: false,
        errorLengthCategory: false
      })
    }, 510);
  }

  /* Category name handler for "Add new category" */
  handlerChangeCategoryName(event) {
    if (this.state.errorFields) {
      this.setState({
        errorNameOnlySpace: false,
        errorCategoryName: false
      });
    }
    if (event.target.value.length > 250) {
      this.setState({
        errorLengthCategory: true
      });
    } else {
      this.setState({
        categoryName: event.target.value
      });
      if (this.state.errorLengthCategory) {
        this.setState({
          errorLengthCategory: false
        });
      }
    }
  }

  /* Modal for add a new category */
  handlerGetModalForNewCategory () {
    this.setState(prevState => ({
      modalAddCategory: !prevState.modalAddCategory
    }));
  }

  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }

  /* Add category handler for submit */
  handlerAddCategorySubmit (event) {
    let that = this;
    let error = false;
    event.preventDefault();
    this.setState({
      isLoadedSubmitCategory: true,
      errorFields: false,
      errorCategoryName: false
    });
    let categoryName = that.validItemEmpty('categoryName');
    if(/^\s*$/.test(categoryName) && categoryName !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    const nameRegex = /^[a-zA-Zа-яА-ЯёЁіІїЇґҐ]+(?:[-—'.\s]?[a-zA-Zа-яА-ЯёЁіІїЇґҐ]*)*$/;
    if (!nameRegex.test(categoryName) && categoryName !== '') {
        error = true;
        this.setState({
          errorCategoryName: true,
          errorFields: true
        });
    }
    if(categoryName === ''){
      error = true;
      this.setState({
        errorFields: true
      });
    }
    if(categoryName.length > 250){
      error = true;
      this.setState({
        errorFields: true
      });
    }
    if(!error){
      getAuth().currentUser.getIdToken().then(function(idToken){
        fetch(`${that.props.queryLink}/api/v1/products/categories`, {
          method: 'POST',
          headers: that.props.queryHeaders(idToken),
          body: JSON.stringify({
            "name": categoryName
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSubmitCategory: false,
                errors: data.errors
              });
            });
          } else {

            fetch(`${that.props.queryLink}/api/v1/products/categories/search`, {
              method: 'POST',
              headers: that.props.queryHeaders(idToken),
              body: JSON.stringify({
                "itemsPerPage": 20,
                "orderField": "creationDate",
                "direction": 'DESC'
              })
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    isLoaded: true,
                    errors: data.errors,
                    error: true
                  });
                })
              } else {
                res.json().then(function(data) {
                  that.setState({
                    categories: data.data,
                    totalPages: data.totalPages,
                    totalItemsCategories: data.totalItems
                  });
                })
              }
          })

            res.json().then(function(data) {
              let newCategory = data;
              newCategory.products = 0;
              that.setState(prevState => ({
                isLoadedSubmitCategory: false,
                categoryNameModal: '',
                successSubmit: true,
                successText: that.props.getTranslate('successStatus-Created'),
                modalSubmitStatus: true,
              }));
            })
            that.handlerRestoreModalData();
            setTimeout(() => {
              that.setState({
                errorSubmit: false,
                successSubmit: false
              });
            }, 5000);
          }
        })
      })
    } else {
      this.setState({
        isLoadedSubmitCategory: false
      });
    }
  }

  /* Get for edit category data */
  handlerEditCategory (index, event) {
    this.setState(prevState => ({
      modalEditCategory: !prevState.modalEditCategory,
      editCategoryObj: {
        categoryID: prevState.categories[index].categoryID,
        companyID: prevState.categories[index].companyID,
        enable: prevState.categories[index].enable,
        name: prevState.categories[index].name
      },
      categoryRowIndex: index
    }));
  }

  /* Edit category name handler */
  handlerNewCategoryName (event) {
    if(this.state.errorNameOnlySpace){
      this.setState({
        errorNameOnlySpace: false,
        errorCategoryName: false
      });
    }
    let obj = this.state.editCategoryObj;
    if (event.target.value.length > 250) {
      this.setState({
        errorLengthCategory: true
      });
    } else {
      obj.name = event.target.value;
      this.setState({
        editCategoryObj: obj
      });
      if (this.state.errorLengthCategory) {
        this.setState({
          errorLengthCategory: false
        });
      }
    }
  }

  /* Category edit handler for submit */
  handlerEditCategorySubmit (event) {
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      isLoadedSubmitCategory: true,
      errorFields: false
    });
    let obj = that.state.editCategoryObj;
    let categoryName = obj.name;
    let onlySpace = /^\s*$/.test(categoryName);
    let itemCorrect;
    if(onlySpace){
      itemCorrect = categoryName;
    } else {
      itemCorrect = categoryName.trim();
    }
    obj.name = itemCorrect;
    this.setState({ editCategoryObj: obj });

    if(/^\s*$/.test(itemCorrect) && itemCorrect !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    const nameRegex = /^[a-zA-Zа-яА-ЯёЁіІїЇґҐ]+(?:[-—\s][a-zA-Zа-яА-ЯёЁіІїЇґҐ]+)*$/;
    if (!nameRegex.test(itemCorrect) && itemCorrect !== '') {
        error = true;
        this.setState({
          errorCategoryName: true,
          errorFields: true
        });
    }
    if(itemCorrect === ''){
      error = true;
      this.setState({
        errorFields: true
      });
    }
    if(itemCorrect.length > 250){
      error = true;
      this.setState({
        errorFields: true
      });
    }
    if(!error){
      getAuth().currentUser.getIdToken().then(function(idToken){
        fetch(`${that.props.queryLink}/api/v1/products/categories/${that.state.editCategoryObj.categoryID}`, {
          method: 'PUT',
          headers: that.props.queryHeaders(idToken),
          body: JSON.stringify(obj)
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSubmitCategory: false,
                errorSubmit: true,
                errors: data.errors
              });
              setTimeout(() => {
                that.setState({
                  errorSubmit: false,
                  successSubmit: false
                });
              }, 5000);
            })
          } else {
              let arr = that.state.categories;
              arr[that.state.categoryRowIndex].name = that.state.editCategoryObj.name;
              that.setState({
                categories: arr,
                isLoadedSubmitCategory: false,
                successSubmit: true,
                successText: that.props.getTranslate('successStatus-Edit'),
                modalSubmitStatus: true
              });
              that.handlerRestoreModalData();
          }
        })
      })
    } else {
      this.setState({
        isLoadedSubmitCategory: false
      });
    }
  }

  /* Handler for change status */
  handlerChangeStatusCategory (id, index, event) {
    let that = this;
    let arr = this.state.categories;
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/products/categories/${id}/enable`, {
        method: 'POST',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({
          "enable": !arr[index].enable,
          "categoryID": id
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        } else {
          arr[index].enable = !arr[index].enable;
          that.setState({
            categories: arr
          });
        }
      })
    })
  }

  /* Handler get modal for delete category */
  handlerDeleteCategoryModal(id, index, event){
    this.setState({
      categoryNameModal: this.state.categories[index].name,
      modalDeleteCategory: true,
      removeIndex: index,
      idDeleteCategory: id
    });
  }

  /* Handler for delete category */
  handlerDeleteCategory (event) {
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    if(this.state.modalRemoveCategory){
      this.setState(prevState => ({
        modalRemoveCategoryLoading: true
      }));
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
    fetch(`${that.props.queryLink}/api/v1/products/categories/${that.state.idDeleteCategory}`, {
      method: 'DELETE',
      headers: that.props.queryHeaders(idToken)
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            loadingDeleteSumbit: false
          });
        })
      } else {
        let arr = that.state.categories;
        arr.splice(that.state.removeIndex, 1);
        that.setState(prevState => ({
            categories: arr,
            categoryRowIndex: that.state.removeIndex,
            successSubmit: true,
            successText: that.props.getTranslate('successStatus-Delete'),
            modalSubmitStatus: true,
            loadingDeleteSumbit: false
        }));


    getAuth().currentUser.getIdToken().then(function(idToken){
        fetch(`${that.props.queryLink}/api/v1/products/categories/search`, {
          method: 'POST',
          headers: that.props.queryHeaders(idToken),
          body: JSON.stringify({
            "itemsPerPage": 20,
            "orderField": "creationDate",
            "direction": 'DESC'
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoaded: true,
                errors: data.errors,
                error: true
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                categories: data.data,
                totalPages: data.totalPages,
                totalItemsCategories: data.totalItems
              });
            })
          }
      })
    })


        that.handlerRestoreModalData();
      }
    })
  })
  }

  /* Pagination handler */
  handlerPaginationItems = (categoriesItems, current) => {
    this.setState({
      categories: categoriesItems,
      currentPage: current
    });
  }

  /* Handler for close success labels */
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successSubmit: false
    }));
  }

  /* Handler for close errors labels */
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  /* Handler get category products */
  handlerGetProducts(id, index, event){
    if(!event.target.closest('.control-wgt')){
      this.setState({
        getCategoryID: id
      });
    }
  }

  /* Handler for close "Add modal" */
  handlerCloseAddModal(event){
    this.setState({
      modalAddCategory: false,
      categoryName: null,
      categoryNameModal: null,
      errorNameOnlySpace: false,
      errorCategoryName: false,
      errorFields: false
    });
  }

  /* Handler for close "Edit modal" */
  handlerCloseEditModal(event){
    setTimeout(() => {
      this.setState({
        modalEditCategory: false,
        categoryNewName: '',
        categoryNameModal: null,
        editCategoryObj: null,
        errorNameOnlySpace: false,
        errorCategoryName: false,
        errorFields: false
      });
    }, 500);
  }

  /* Handler for close "Delete modal" */
  handlerCloseDeleteModal(event){
    this.setState({
      modalDeleteCategory: false,
      removeIndex: null,
      idDeleteCategory: null
    });
  }

  /* Render component */
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('catalog-Title') + ' | ' + this.props.companyName;
    }
    if(this.state.errors){
      setTimeout(() => {
        this.handlerCloseError();
      }, 5000);
    }
    if(this.state.successSubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultCategories = '';
    if(this.state.error) {
      resultCategories = <div>{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
    } else if(!this.state.isLoaded) {
      resultCategories = <Loading />;
    } else {
      resultCategories = <>

          <div className="account-wgt__inner">
                <div className="header-content-nav">

                  {/* Button for add category */}
                  <Button
                    type="button"
                    icon={true}
                    iconItem="#plus-icon"
                    text={this.props.getTranslate('catalog-AddCategoryButton')}
                    addClass="header-content-nav__btn"
                    handler={this.handlerGetModalForNewCategory.bind(this)}
                  />

                </div>
              </div>

          <div className="account-wgt account-wgt__catalog">

            {/* Table component */}
            {this.state.categories ? <Table
              nameWidth="70%"
              header={[
                this.props.getTranslate('catalog-NameList'),
                this.props.getTranslate('catalog-CountItemsList'),
                ''
              ]}
              handlerRow={this.handlerGetProducts.bind(this)}
              bodyData={this.state.categories}
              bodyDataNames={['name', 'products']}
              controlItemsStatus={true}
              controlItems={{
                'enabled': {'status': true, 'handler': this.handlerChangeStatusCategory.bind(this), 'label': {'hide': this.props.getTranslate('tableDropdown-StatusLabel'), 'show': this.props.getTranslate('tableDropdown-StatusLabelShow')}},
                'editButton': {'status': true, 'handler': this.handlerEditCategory.bind(this), 'label': this.props.getTranslate('tableDropdown-EditLabel')},
                'editLink': false,
                'delete': {'status': true, 'handler': this.handlerDeleteCategoryModal.bind(this), 'label': this.props.getTranslate('tableDropdown-EditRemove')}
              }}
              enableTooltipMessage={this.props.getTranslate('tableEnable-TooltipMessage')}
              itemWithoutCategoryName={this.props.getTranslate('catalog-withoutCategoryName')}
              itemIDName={'categoryID'}
              itemEnableName={'enable'}
              removeIndex={this.state.removeIndex}
            /> : ''}

          </div></>
    }
    return <>

    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Query result handler */}
    <ResultQuery
      success={this.state.successSubmit}
      resultText={this.state.successText}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    />

    {/* Redirect to category products page */}
    {this.state.getCategoryID && <Navigate to={this.props.getPermalink(`/account/products/${this.state.getCategoryID}`)} replace={false} />}

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

      {/* Navigation component */}
      <AccountNav
        getPermalink={this.props.getPermalink}
        queryLink={this.props.queryLink}
        configsApp={this.props.configsApp}
        newReview={this.props.newReview}
        companyName={this.props.companyName}
        token={this.props.token()}
        status={this.state.mobileMenu}
        statusStart={this.state.mobileMenuStart}
        handler={this.handlerMobileMenu}
        dropdownStatus={this.state.dropdownStatus}
        dropdownHandler={this.handlerDropdown}
        getTranslate={this.props.getTranslate}
        completed={this.props.completed}
      />

      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            title={this.props.getTranslate('catalog-Title')}
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
          />

          {/* Tabs component */}
          <TabItems items={[
            {
              disabled: this.props.configsApp && this.props.configsApp.shop === true ? false : true,
              label: this.props.getTranslate('tabItem-ItemProducts'),
              path: this.props.getPermalink('/account/products'),
              amount: this.state.totalItemsCategories
            },
            {
              disabled: this.props.configsApp && this.props.configsApp.customShop === true ? false : true,
              label: this.props.getTranslate('tabItem-ItemShops'),
              path: this.props.getPermalink('/account/shops'),
              amount: this.state.totalItems
            }
          ]} />

          {this.state.categories && this.state.categories.length < 1 && this.state.isLoaded && !this.state.error ?<>
            <div className="account-content__content account-content__content_empty">
              <div className="empty-wrapper">

                {/* If the response is empty */}
                <AccountContentEmpty
                  img={category_img}
                  title={this.props.getTranslate('catalog-EmptyCategotyTitle')}
                  description={this.props.getTranslate('catalog-EmptyCategotyDescription')}
                  buttonType="button"
                  buttonText={this.props.getTranslate('catalog-AddCategoryButton')}
                  buttonIcon="#plus-icon"
                  buttonHandler={this.handlerGetModalForNewCategory.bind(this)}
                />

              </div>
            </div></>
              :
            <><div className="account-content__content">

              {/* Query result */}
              {resultCategories}

              </div>
              <div className="account-content__footer">

                {/* Pagination component */}
                <Pagination
                  total={this.state.totalPages}
                  perPage="20"
                  path={`${this.props.queryLink}/api/v1/products/categories/search`}
                  currentPage={this.state.currentPage}
                  otherParams={{"orderField": 'creationDate', "direction": 'DESC', "companyID": this.state.companyID}}
                  items={this.handlerPaginationItems}
                />
              </div></>}
          </div>
      </div>
    </div>

    {
      /* Modal all components */
      /* Modal Add Category */
    }
    {this.state.modalAddCategory ? <Modal
      title={this.props.getTranslate('catalog-AddCategoryTitle')}
      content={<div className="field-wrap">
              <label className="field-wrap__label" htmlFor="name-category">{this.props.getTranslate('catalog-NameCategory')}</label>
              <input id="name-category" className={`field-wrap__input ${(this.state.errorFields && !this.state.categoryName) || this.state.errorNameOnlySpace || this.state.errorCategoryName || this.state.errorLengthCategory ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.categoryName} onChange={this.handlerChangeCategoryName.bind(this)} placeholder={this.props.getTranslate('catalog-NameCategoryFiledCaption')} />
              {this.state.errorFields && this.state.categoryName === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
              {this.state.errorNameOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
              {this.state.errorCategoryName && !this.state.errorNameOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-CategoryFormatIncorrect')}</div> : ''}
              {this.state.errorLengthCategory ? <div className="validation-error">{this.props.getTranslate('validation-CategoryProductLengthError')}</div> : ''}
            </div>}
      type={'formSubmit'}
      buttonSubmitText={this.props.getTranslate('catalog-CreateCategoryButton')}
      submitHandler={this.handlerAddCategorySubmit.bind(this)}
      submitLoading={this.state.isLoadedSubmitCategory}
      handlerClose={this.handlerCloseAddModal.bind(this)}
      submitStatus={this.state.modalSubmitStatus}
    /> : ''}

    {/*  Modal Edit Category */}
    {this.state.modalEditCategory ? <Modal
      title={this.props.getTranslate('catalog-EditCategoryTitle')}
      content={<div className="field-wrap">
                  <label className="field-wrap__label" htmlFor="name-category">{this.props.getTranslate('catalog-NameCategory')}</label>
                  <input id="name-category" className={`field-wrap__input ${(this.state.errorFields && !this.state.editCategoryObj.name) || this.state.errorNameOnlySpace || this.state.errorCategoryName || this.state.errorLengthCategory ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.editCategoryObj.name} onChange={this.handlerNewCategoryName.bind(this)} placeholder={this.props.getTranslate('catalog-NameCategoryFiledCaption')} />
                  {this.state.errorFields && this.state.editCategoryObj.name === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  {this.state.errorNameOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                  {this.state.errorCategoryName && !this.state.errorNameOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-CategoryFormatIncorrect')}</div> : ''}
                  {this.state.errorLengthCategory ? <div className="validation-error">{this.props.getTranslate('validation-CategoryProductLengthError')}</div> : ''}
                </div>}
      type={'formSubmit'}
      buttonSubmitText={this.props.getTranslate('catalog-EditCategoryButton')}
      submitHandler={this.handlerEditCategorySubmit.bind(this)}
      submitLoading={this.state.isLoadedSubmitCategory}
      handlerClose={this.handlerCloseEditModal.bind(this)}
      submitStatus={this.state.modalSubmitStatus}
    /> : ''}

    {/* Modal Delete Category */}
    {this.state.modalDeleteCategory ? <Modal
      title={this.props.getTranslate('removeModal-ProductCategoryTitle')}
      content={<div className="delete-category-form__description">{this.props.getTranslate('removeModal-ProductCategoryDescriptionBefore')} «{this.state.categoryNameModal}» {this.props.getTranslate('removeModal-ProductCategoryDescriptionAfter')}</div>}
      type={'delete'}
      buttonSubmitText={this.props.getTranslate('removeModal-ButtonSubmit')}
      submitHandler={this.handlerDeleteCategory.bind(this)}
      submitLoading={this.state.loadingDeleteSumbit}
      handlerClose={this.handlerCloseDeleteModal.bind(this)}
      submitStatus={this.state.modalSubmitStatus}
    /> : ''}
    </>;
  }
}
export default Products;
