import React from "react";
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import FilterSearch from '../components/FilterSearch';
import Loading from './components/Loading';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ControlPanelUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      searchUsersValue: '',
      usersData: [],
      isLoaded: false,
      totalPages: null,
      totalItems: null,
      controlRow: null,
      currentPage: 0,
      searchEmpty: false,
      timer: null
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false
      }));
    }, 500);
  }
  handlerPaginationItems = (usersData, current) => {
    this.setState({
      usersData: usersData,
      currentPage: current
    });
  }
  componentDidMount() {
    const div = document.querySelector('.control-wgt');
    if (div) {
      document.addEventListener('click', (e) => {
        const isControlBtn = e.target.closest('.control-content__btn');
        if (isControlBtn) {
          this.setState({
            controlRow: null
          });
        } else {
          const withinBoundaries = e.composedPath().includes(div);
           if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
            if (!withinBoundaries) {
              this.setState({
                controlRow: null
              });
            }
          }
        }
      });
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "itemsPerPage": 20,
          "allUsers": true
        })
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              usersData: data.data,
              totalPages: data.totalPages,
              isLoaded: true,
              totalItems: data.totalItems
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        }
      })
    });
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerDelete(id, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/users/${id}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if (res.status === 200) {
          that.setState({
            controlRow: null
          });
            fetch(`${that.props.queryLink}/bo/v1/users/search`, {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({
                "itemsPerPage": 20,
                "allUsers": true
              })
            }).then(res => {
              if (res.status === 200) {
                res.json().then(function(data) {
                  that.setState({
                    usersData: data.data,
                    totalPages: data.totalPages,
                    totalItems: data.totalItems
                  });
                })
              } else {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  });
                })
              }
            })
        } else {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        }
      })
    })
  }
  handlerSearch(event) {
    let that = this;
      this.setState({
      isLoadedSearch: true,
      searchUsersValue: event.target.value,
      searchEmpty: false
    });
    let timeoutId = setTimeout(() => {
      getAuth().currentUser.getIdToken().then(function(idToken) {
              const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/bo/v1/users/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "query": that.state.searchUsersValue, "allUsers": true})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false
              });
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false,
                usersData: data.data,
                totalPages: data.totalPages,
                totalItems: data.totalItems
              });
              if(data.data.length < 1){
                that.setState({
                  searchEmpty: true
                });
              }
            });
          }
        });
      });
    }, 500);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = timeoutId;
  }
  render() {
    let dataTable = '';
    if(this.state.error) {
      dataTable = 'Помилка';
    } else if(!this.state.isLoaded){
      dataTable = <Loading />;
    } else {
      dataTable = <table className="data-table">
        <thead>
          <tr>
            <th>ФІО</th>
            <th>E-mail</th>
            <th>Компанія</th>
            <th>Доступи</th>
            <th>Статус</th>
            <th>Управління</th>
          </tr>
        </thead>
        <tbody>
          {this.state.usersData.map((item, index) => (
            <tr key={index}>
              <td>{item.fullName}</td>
              <td>{item.email}</td>
              <td>{item.email}</td>
              <td className="team-table__rights">
                <>
                  {item.companyOwner && (
                    <div className="team-table__rights-tooltip" style={{ paddingRight: '3px' }} key={`${index}-owner`}>
                      <svg className="team-table__rights-icon">
                        <use href={`${sprite}#access-owner-icon`}></use>
                      </svg>
                      <span>Власник</span>
                    </div>
                  )}

                  {Array.isArray(item.roles) && item.roles.length > 0 &&
                    item.roles.map((roleItem, roleIndex) => {
                      let roleData = null;

                      if (roleItem === 'ROLE_ADMINISTRATOR') {
                        roleData = {
                          icon: 'access-admin-icon',
                          label: 'Администратор'
                        };
                      } else if (roleItem === 'ROLE_EMPLOYEE') {
                        roleData = {
                          icon: 'access-user-icon',
                          label: 'Користувач'
                        };
                      }

                      return roleData ? (
                        <div className="team-table__rights-tooltip" style={{ paddingRight: '3px' }} key={`${index}-${roleIndex}`}>
                          <svg className="team-table__rights-icon">
                            <use href={`${sprite}#${roleData.icon}`}></use>
                          </svg>
                          <span>{roleData.label}</span>
                        </div>
                      ) : null;
                    })
                  }
                </>
              </td>
              <td>
                {item.userStatus === 'ACTIVE' ? <div className="bo-status-label bo-status-label_theme_green">Активний</div> : <div className="bo-status-label bo-status-label_theme_gray">Не активний</div>}
              </td>
              <td className="catalog-table__control">
                <div className="control-wgt">
                  <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                    <svg className="control-btn__icon">
                      <use href={`${sprite}#more-icon`}></use>
                    </svg>
                  </button>
                  <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                    <ul className="control-content__list">
                      <li>
                        <button className="control-content__btn control-content__btn_delete" onClick={this.handlerDelete.bind(this, item.userID)}>
                          <svg className="control-content__icon">
                            <use href={`${sprite}#delete-red`}></use>
                          </svg>Видалити</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>

      </table>
    }
    return <>
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin">
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Користувачі</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="team-nav team-nav_space_end">
              {/* Filter search input component */}
              <FilterSearch
                placeholder="Пошук"
                value={this.state.searchUsersValue}
                handler={this.handlerSearch.bind(this)}
              />
            </div>
            <div className="account-content__content">
              <div className="account-wgt">
                {dataTable}
              </div>
            </div>
            <div className="account-content__footer">
              <Pagination total={this.state.totalPages} perPage="20" path={`${this.props.queryLink}/bo/v1/users/search`} currentPage={this.state.currentPage} items={this.handlerPaginationItems} otherParams={{label: this.state.searchUsersValue, allUsers: true}} />
            </div>
          </div>
        </div>
      </div>
    </>
  }
}
export default ControlPanelUsers;
