import React from "react";
import DateFormating from './DateFormating';
import LabelStatus from './LabelStatus';
import { Link } from "react-router-dom";
import Loading from './Loading';
import styles from './Table.css';
import sprite from '../media/icons.svg';

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlRow: null,
      windowWidth: window.innerWidth,
      libraryLabel: false,
      indexHover: null
    }
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    const div = document.querySelector('.control-wgt');
    if (div) {
      document.addEventListener('click', (e) => {
        const isControlBtn = e.target.closest('.control-content__btn');
        if (isControlBtn) {
          this.setState({
            controlRow: null
          });
        } else {
          const withinBoundaries = e.composedPath().includes(div);
           if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
            if (!withinBoundaries) {
              this.setState({
                controlRow: null
              });
            }
          }
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }

  handlerMouseEnter(index, event){
    this.setState({
      indexHover: index
    });
  }

  handlerMouseLeave(event){
    this.setState({
      indexHover: null
    });
  }

  handlerEmpty(){
    return;
  }
  render() {
    const isSmallScreen = this.state.windowWidth < 992;

    return isSmallScreen && this.props.enabledMobileContent ?
    <div className="data-mobile">
      {this.props.bodyData.map((item, index) => {
        return this.props.mobileContentType === 'block' ? <div className="data-mobile-block__wgt" key={index}><div className="data-mobile-block__wgt-inner" key={index}>
        {this.props.mobileContentData.map((itemName, indexName) => {
          let itemContent = '';
          if(!Array.isArray(itemName)){
            if(this.props.dateValueFormat && item[itemName] && (this.props.dateValueFormat.start === itemName || this.props.dateValueFormat.end === itemName)){
              itemContent = <DateFormating start={this.props.dateValueFormat.start === itemName ? item[itemName] : null} end={this.props.dateValueFormat.end === itemName ? item[itemName] : null} />
            } else if(this.props.translationValueItems && typeof this.props.translationValueItems[item[itemName]] !== "undefined" && item[itemName]) {
              if(this.props.labelsStatus && typeof this.props.labelsStatus[itemName] !== "undefined" && item[itemName]){
                itemContent = <LabelStatus name={this.props.translationValueItems[item[itemName]]} theme={this.props.labelsStatus[itemName][item[itemName]]} type="txt" />
              } else {
                itemContent = item[itemName];
              }
            } else {
              if(itemName === 'recurring' && !item[itemName]){
               itemContent = this.props.reccuringNull;
             } else if(itemName === 'acceptedCount') {
               itemContent = (item.acceptedCount + ' / ' + (item.employeeCount ? item.employeeCount : 0));
             } else if(itemName === 'userCount') {
               itemContent = (item.userCount ? item.userCount : 0);
             } else if(itemName === 'pollsResult') {
               return <>
                        {item.progress ? <Link className="questions-table__result" to={this.props.pollsToResult + item[this.props.itemIDName]}>{this.props.pollsToResultTxt}</Link> : <span className="questions-table__result questions-table__result_empty">{this.props.pollsToResultTxt}</span>}
                      </>
             } else {
               itemContent = item[itemName];
             }
            }
          } else {
            if(itemName[0] === 'startTime' && itemName[1] === 'endTime' && itemName[0] === this.props.dateValueFormat.start && itemName[1] === this.props.dateValueFormat.end){
              itemContent = <DateFormating start={this.props.dateValueFormat.start === itemName[0] ? item[itemName[0]] : null} end={this.props.dateValueFormat.end === itemName[1] ? item[itemName[1]] : null} time={this.props.dateValueFormat.timeOff ? 'off' : null} timeEnd={this.props.dateValueFormat.timeEnd} separator={this.props.dateValueFormat.separatorTime} />
            } else {
              itemContent = this.props.translationValueItems[item[itemName]];
            }
          }
          return <>
          {indexName < 1 && <div className="data-mobile-block__wgt-header" key={indexName}>
            {!this.props.mobileOpenView ? <div className="data-mobile-block__wgt-name">
              {this.props.controlItems.editLink && this.props.controlItems.editLink.handler ? <Link to={this.props.controlItems.editLink.handler + item[this.props.itemIDName]}>{item[itemName]}</Link> : ''}
              {this.props.controlItems.editButton && this.props.controlItems.editButton.handler ? <span onClick={this.props.controlItems.editButton.handler.bind(this, index, item[this.props.itemIDName])}>{item[itemName]}</span> : ''}
            </div> : <div className="data-mobile-block__wgt-name"><span onClick={this.props.handlerRow ? this.props.handlerRow.bind(this,  (item[this.props.itemIDName] ? item[this.props.itemIDName] : 'no-category'), index) : this.handlerEmpty.bind(this)}>{item[itemName]}</span></div>}
            <div className="question-nav order-wgt__nav">
              <Link className="question-wgt__nav-btn" to={this.props.controlItems.editLink.handler + item[this.props.itemIDName]}>
                <svg className="question-wgt__nav-icon">
                  <use href={`${sprite}#edit`}></use>
                </svg>
              </Link>
              <button className="question-wgt__nav-btn" type="button" onClick={this.props.controlItems.delete.handler.bind(this, item[this.props.itemIDName], index)}>
                <svg className="question-wgt__nav-icon">
                  <use href={`${sprite}#delete`}></use>
                </svg>
              </button>
            </div>
          </div>}
          {indexName >= 1 && <div className="data-mobile-block__wgt-content-wgt" key={indexName}>
                  <div className="question-wgt__content-wgt-label">{this.props.mobileContentHeader[indexName -1]}</div>
                  <div className="question-wgt__content-wgt-value">{itemContent}</div>
                </div>}
          </>
        })}
        </div></div> : <div className="data-mobile__wgt" key={index}>
          <div className="data-mobile__wgt-content">
          {this.props.mobileContentData.map((itemName, indexName) => {
            let itemContent = '';
            if(!Array.isArray(itemName)){
              if(this.props.dateValueFormat && item[itemName] && (this.props.dateValueFormat.start === itemName || this.props.dateValueFormat.end === itemName)){
                itemContent = <DateFormating start={this.props.dateValueFormat.start === itemName ? item[itemName] : null} end={this.props.dateValueFormat.end === itemName ? item[itemName] : null} />
              } else if(this.props.translationValueItems && typeof this.props.translationValueItems[item[itemName]] !== "undefined" && item[itemName]) {
                if(this.props.labelsStatus && typeof this.props.labelsStatus[itemName] !== "undefined" && item[itemName]){
                  itemContent = <LabelStatus name={this.props.translationValueItems[item[itemName]]} theme={this.props.labelsStatus[itemName][item[itemName]]} />
                } else {
                  itemContent = item[itemName];
                }
              } else {
                itemContent = this.props.translationValueItems[item[itemName]];
              }
            }
            return (indexName < 1 ? <div key={indexName} className="data-mobile__wgt-name">{item[itemName] ? item[itemName] : this.props.itemWithoutCategoryName}

            <div className={`control-wgt${this.props.userTable ? ' control-wgt__pos' : ''}`}>
              <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                <svg className="control-btn__icon">
                  <use href={`${sprite}#more-icon`}></use>
                </svg>
              </button>
              <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                <ul className="control-content__list">
                {this.props.controlItems.sendCalculation && this.props.controlItems.sendCalculation.status === true ?
                  <li>
                  <button className="control-content__btn" onClick={this.props.controlItems.sendCalculation.handler.bind(this, item[this.props.itemIDName], ((item.name ? item.name + ' ' : '') + (item.middleName ? item.middleName + ' ' : '') + (item.lastName ? item.lastName : '')), 'list')}>
                    <svg className="control-content__icon">
                      <use href={`${sprite}#deposit-icon`}></use>
                    </svg>{this.props.controlItems.sendCalculation.label}</button>
                  </li> : ''}
                {this.props.controlItems.sendMessage && this.props.controlItems.sendMessage.status === true ?
                  <li>
                  <button className="control-content__btn">
                    <svg className="control-content__icon">
                      <use href={`${sprite}#messages-icon-stroke`}></use>
                    </svg>{this.props.controlItems.sendMessage.label}</button>
                  </li> : ''}
                  {this.props.controlItems.enabled.status === true ?
                    <li>
                      <button className="control-content__btn" type="button" onClick={this.props.controlItems.enabled.handler.bind(this, item[this.props.itemIDName], index, item.bookingUserName ? true : false)}>
                        <svg className="control-content__icon">  <use href={`${sprite}${(this.props.itemEnableNameForStatus && (item[this.props.itemEnableNameForStatusData] === this.props.itemEnableNameForStatus)) || (!item[this.props.itemEnableName] && item[this.props.itemIDName] && this.props.itemEnableName) ? '#view-icon-stroke-show' : '#view-icon-stroke'}`}></use></svg>
                        {(this.props.itemEnableNameForStatus && (item[this.props.itemEnableNameForStatusData] === this.props.itemEnableNameForStatus)) || (!item[this.props.itemEnableName] && item[this.props.itemIDName] && this.props.itemEnableName) ? this.props.controlItems.enabled.label.show : this.props.controlItems.enabled.label.hide}
                      </button>
                    </li> : ''}
                    {this.props.controlItems.editButton.status === true ?
                      <li>
                        <button className="control-content__btn" type="button" onClick={this.props.controlItems.editButton.handler.bind(this, index, item[this.props.itemIDName])}>
                          <svg className="control-content__icon">  <use href={`${sprite}#edit-icon`}></use></svg>
                          {this.props.controlItems.editButton.label}
                        </button>
                      </li> : ''}
                      {this.props.controlItems.editLink.status === true ?
                        <li>
                        {this.props.controlItems.editLink.state ? <Link className="control-content__btn" to={this.props.controlItems.editLink.handler + item[this.props.itemIDName]} state={{from: this.props.controlItems.editLink.state}}>
                                              <svg className="control-content__icon">
                                                <use href={`${sprite}#edit-icon`}></use>
                                              </svg>{this.props.controlItems.editLink.label}</Link> : <Link className="control-content__btn" to={this.props.controlItems.editLink.handler + item[this.props.itemIDName]}>
                                              <svg className="control-content__icon">
                                                <use href={`${sprite}#edit-icon`}></use>
                                              </svg>{this.props.controlItems.editLink.label}</Link>}
                        </li> : ''}
                      {this.props.controlItems.delete.status === true ?
                        <li>
                          <button className="control-content__btn control-content__btn_delete" onClick={this.props.controlItems.delete.handler.bind(this, item[this.props.itemIDName], index)}>
                            <svg className="control-content__icon">
                              <use href={`${sprite}#delete-red`}></use>
                            </svg>{this.props.controlItems.delete.label}</button>
                        </li> : ''}
                </ul>
              </div>
            </div>

            </div> : <div key={indexName} className="data-mobile__wgt-col">
                {Array.isArray(itemName) ?
                    <>
                      {itemName.map((itemArray, indexArray) => {
                        if(this.props.dateValueFormat && itemArray && (this.props.dateValueFormat.start === itemArray || this.props.dateValueFormat.end === itemArray)){
                          return <span key={indexArray}> <DateFormating start={this.props.dateValueFormat.start === itemArray ? item[itemArray] : null} end={this.props.dateValueFormat.end === itemArray ? item[itemArray] : null} /></span>
                        } else if(this.props.translationValueItems && typeof this.props.translationValueItems[itemArray] !== "undefined" && itemArray) {
                          if(this.props.labelsStatus && typeof this.props.labelsStatus[itemArray] !== "undefined" && itemArray){
                            return <LabelStatus name={this.props.translationValueItems[item[itemName]]} theme={this.props.labelsStatus[itemName][item[itemName]]} />;
                          } else {
                            return this.props.translationValueItems[item[itemName]];
                          }
                        } else {
                          return <span key={indexArray}>{item[itemArray]}</span>
                        }
                      })}
                    </>
                   : <span>{itemContent}</span>}
              </div>)
          })}
          </div>
        </div>
      })}
    </div> : <table className="data-table">
      <thead>
        <tr>
          {this.props.header.map((item, index) => {
            if(this.props.nameWidth && index < 1){
              if(item.handler){
                return <th key={index} style={{width: this.props.nameWidth}} onClick={item.handler}>{item.label}</th>
              } else {
                return <th key={index} style={{width: this.props.nameWidth}}>{item}</th>
              }
            } else {
              if(item.handler){
                return <th key={index} onClick={item.handler}>{item.label}</th>
              } else {
                return <th key={index}>{item}</th>
              }
            }
          })}
        </tr>
      </thead>
      <tbody>
        {this.props.bodyData.map((item, index) => {
          return <tr className={(this.props.hightlightStatus &&  this.props.hightlightStatus[Object.keys(this.props.hightlightStatus)[0]].indexOf(item[Object.keys(this.props.hightlightStatus)[0]]) !== -1 ? 'hightlight' : '') || (this.props.itemEnableNameForStatus ? (item[this.props.itemEnableNameForStatusData] === this.props.itemEnableNameForStatus ? 'disabled' : '') : ((!item[this.props.itemIDName] || (!item[this.props.itemEnableName] && this.props.itemEnableName) ? 'disabled' : '')))} key={index} onClick={this.props.handlerRow ? this.props.handlerRow.bind(this,  (item[this.props.itemIDName] ? item[this.props.itemIDName] : 'no-category'), index) : this.handlerEmpty.bind(this)}>
          <>
            {this.props.bodyDataNames.map((itemName, indexName) => {
              let libraryStatus = '';
               if(this.props.libraryStatus){
                if(item.bookingUserName && itemName === 'bookingUserName'){
                  let dateStart = new Date(Date.parse(item.bookingDate));
                  let valueDateStart = dateStart.getDate();
                  let valueMonthStart = dateStart.getMonth() + 1;
                  let valueYearStart = dateStart.getFullYear();
                  if(valueDateStart < 10){
                    valueDateStart = '0' + valueDateStart;
                  }
                  if(valueMonthStart < 10){
                    valueMonthStart = '0' + valueMonthStart;
                  }
                  let dateFull = valueDateStart + '.' + valueMonthStart + '.' + valueYearStart;
                  let userName = <>{this.props.libraryStatusTaken[0]} <span dangerouslySetInnerHTML={{__html: item.bookingUserName}}></span> {this.props.libraryStatusTaken[1]} {dateFull}</>;
                  libraryStatus = <LabelStatus name={userName} theme="booking" />
                }
              }
              if(this.props.checkboxItems && indexName === 0){
                return <td className="team-table__choose">
                        <div>
                          <input className="styled-checkbox" id={`styled-checkbox-${item[this.props.itemIDName]}`} type="checkbox" checked={this.props.usersIDs.findIndex(x => x.id === item[this.props.itemIDName]) !== -1 || this.props.allUsers ? true : false} onChange={this.props.checkboxesHandler.bind(this, item[this.props.itemIDName], (item.name ? item.name + ' ' : '')  + (item.middleName ? item.middleName + ' ' : '') + (item.lastName ? item.lastName : ''), null)} />
                          <label htmlFor={`styled-checkbox-${item[this.props.itemIDName]}`}></label>
                        </div>
                      </td>
              }
              if(indexName < 1){
                if(this.props.rewardsTable) {
                 return <td>
                  <div className="team-table__name-flex">
                   {item.photoUrl ? <div className="team-table__rewards-photo-wrap"><img className="team-table__rewards-photo" src={item.photoUrl} alt="" onMouseEnter={this.handlerMouseEnter.bind(this, index)} onMouseLeave={this.handlerMouseLeave.bind(this)} />{this.state.indexHover === index ? <div className="team-table__rewards-photo-hover-wrap"><img className="team-table__rewards-photo-hover" src={item.photoUrl} alt="" /></div> : ''}</div> : ''}
                   <span className="team-table__name-val">{item.name}</span>
                  </div>
                 </td>
               }
                if(this.props.boUsers){
                  return <td><div className="team-table__name-flex">
                    <div className="team-table__name-photo-wrap">
                      {!item.completed && <div className="team-table__name-photo-wrap_overlay"></div>}
                      <img className="team-table__name-photo" src={item.photo} alt="" />
                      {!item.completed && <div className="tooltips-wrap tooltips-wrap__users">
                        <div>
                          <div className="tooltip-info__icon">
                            <svg className="edit-user-form__activation-icon">
                              <use href={`${sprite}#reload-icon`}></use>
                            </svg>
                          </div>
                        </div>
                      </div>}
                    </div>
                    <span className="team-table__name-val" style={{opacity: item.completed ? '1' : '0.3'}}>{item.fullName ? item.fullName : item.email}</span>
                    </div>
                    <span className="team-table__name-inner">
                      <span className="team-table__name_info">
                        <div className="team-table__name_email">{item.email && item.email}</div>
                      </span>
                    </span></td>
                }
                  if(this.props.checkboxItems){
                    return <td className="team-table__choose">
                            <div>
                              <input className="styled-checkbox" id={`styled-checkbox-${item[this.props.itemIDName]}`} type="checkbox" />
                              <label htmlFor={`styled-checkbox-${item[this.props.itemIDName]}`}></label>
                            </div>
                          </td>
                } else {
                  return <td className="catalog-table__name" key={indexName}>{item[itemName] ? item[itemName] : this.props.itemWithoutCategoryName}</td>
                }
              } else {
                if(this.props.translationValueItems && typeof this.props.translationValueItems[item[itemName]] !== "undefined" && item[itemName]){
                  if(this.props.labelsStatus && typeof this.props.labelsStatus[itemName] !== "undefined" && item[itemName]){
                    return <td className="catalog-table__name" key={indexName}><LabelStatus name={this.props.translationValueItems[item[itemName]]} theme={this.props.labelsStatus[itemName][item[itemName]]} /></td>
                  } else {
                    return <td className="catalog-table__name" key={indexName}>{this.props.translationValueItems[item[itemName]]} {this.props.shopName ? item[this.props.shopName] : ''}</td>
                  }
                } else if(this.props.dateValueFormat && item[itemName] && (this.props.dateValueFormat.start === itemName || this.props.dateValueFormat.end === itemName)) {
                  return <td className="catalog-table__name catalog-table__date" key={indexName}><DateFormating start={this.props.dateValueFormat.start === itemName ? item[itemName] : null} end={this.props.dateValueFormat.end === itemName ? item[itemName] : null} /></td>
                } else if(this.props.currencyLabel && typeof this.props.currencyLabel[itemName] !== "undefined" && item[itemName]) {
                  return <td className={`${this.props.userTable && itemName === 'balance' ? 'team-table__balance' : ''} ${this.props.hightlightsCol && this.props.hightlightsCol.indexOf(itemName) !== -1 ? 'catalog-table__name-hightlight' : ''} catalog-table__name`} key={indexName}>{item[itemName] + ' ' + this.props.currencyLabel[itemName]}</td>
                } else if(this.props.userTable && indexName === 1 && !this.props.boUsers){
                                    return <td><div className="team-table__name-flex">
                                      <div className="team-table__name-photo-wrap">
                                        {!item.completed && <div className="team-table__name-photo-wrap_overlay"></div>}
                                        <img className="team-table__name-photo" src={item.photo} alt="" />
                                        {!item.completed && <div className="tooltips-wrap tooltips-wrap__users">
                                          <div>
                                            <div className="tooltip-info__icon">
                                              <svg className="edit-user-form__activation-icon">
                                                <use href={`${sprite}#reload-icon`}></use>
                                              </svg>
                                            </div>
                                            {this.props.confirmationPendingBtnHandler ? <div className="tooltip-info__content">
                                              <p>{this.props.confirmationPendingLabel}</p>
                                              <div className="activation-verify-btn">
                                                <button className="edit-user-form__send" type="button" onClick={this.props.confirmationPendingBtnHandler.bind(this, item[this.props.itemIDName], item.email)}>{this.props.confirmationPendingBtnLabel}</button>
                                                {this.props.loadingVerify ? <Loading /> : ''}
                                                {this.props.sendSuccessActivity && !this.props.loadingVerify ? <svg className="user-statistic__status-complated-icon">
                                                  <use href={`${sprite}#success-send-complated`}></use>
                                                </svg> : ''}
                                              </div>
                                            </div> : ''}
                                          </div>
                                        </div>}
                                      </div>
                                      <span className="team-table__name-val" style={{opacity: item.completed ? '1' : '0.3'}}>{item.name && item.lastName ? item.name + ' ' + (item.middleName ? item.middleName + ' ' : '') + item.lastName : item.email}</span>
                                      </div>
                                      <span className="team-table__name-inner">
                                        <span className="team-table__name_info">
                                          <div className="team-table__name_position">{item.position && item.position}</div>
                                          <div className="team-table__name_email">{item.email && item.email}</div>
                                        </span>
                                      </span></td>
                                  } else {
                  if(Array.isArray(itemName)) {
                    if(itemName[0] === 'startTime' && itemName[1] === 'endTime' && itemName[0] === this.props.dateValueFormat.start && itemName[1] === this.props.dateValueFormat.end){
                      return <td className="catalog-table__name catalog-table__date" key={indexName}><DateFormating start={this.props.dateValueFormat.start === itemName[0] ? item[itemName[0]] : null} end={this.props.dateValueFormat.end === itemName[1] ? item[itemName[1]] : null} time={this.props.dateValueFormat.timeOff ? 'off' : null} timeEnd={this.props.dateValueFormat.timeEnd} separator={this.props.dateValueFormat.separatorTime} /></td>
                    } else if(itemName[0] === 'employeeCount' && itemName[1] === 'allUsers') {
                      if(itemName[1] === 'allUsers' && item[itemName[1]]){
                        return <td className="catalog-table__name" key={indexName}>{this.props.translationValueItems[itemName[1]]}</td>
                      } else {
                        return <td className="catalog-table__name" key={indexName}>{item[itemName[0]] ? item[itemName[0]] : '0'}</td>
                      }
                    } else if(itemName[0] === 'userCount' && itemName[1] === 'companyLoyalty') {
                        if(itemName[1] === 'companyLoyalty' && item[itemName[1]]){
                          return <td className="catalog-table__name" key={indexName}>{this.props.translationValueItems[itemName[1]]}</td>
                        } else {
                        return <td className="catalog-table__name" key={indexName}>{item[itemName[0]] ? item[itemName[0]] : '0'}</td>
                        }
                      } else {
                      return <td className="catalog-table__name" key={indexName}>
                      <span className="data-table__count-wrap">
                        {itemName.map((itemArr, indexArr) => {
                          let returnData = '';
                          if(this.props.itemIDName === 'bookID') {
                            if(indexArr === 0) {
                              returnData =<span key={indexArr}>{item[itemArr]}</span>
                            } else {
                              returnData = item[itemArr] ? <span className="data-table__count" key={indexArr}>{item[itemArr]}</span> : ''
                            }
                          }
                          return returnData;
                        })}
                        </span>
                      </td>
                    }
                 } else if(itemName === 'roles') {
                   return <td className="team-table__rights">{item[itemName].sort().map((itemRole, index) => {
                     return this.props.usersRoles[itemRole];
                   })}
                   {item.companyOwner ? this.props.userSelf : ''}
                   </td>
                 } else if(itemName === 'enabled' && this.props.rewardsTable){
                   return <td className="catalog-table__name" key={indexName}>{item.enabled ? <LabelStatus name={this.props.translationValueItems['ACTIVE']} theme="green" /> : <LabelStatus name={this.props.translationValueItems['HIDDEN']} theme="red" />}</td>
                 } else if(itemName === 'recurring' && !item[itemName]){
                   return <td className="catalog-table__name" key={indexName}>{this.props.reccuringNull}</td>
                 } else if(itemName === 'progress') {
                   return <td className="catalog-table__name" key={indexName}>{item.progress + '%'}</td>
                 } else if(itemName === 'acceptedCount') {
                   return <td className="catalog-table__name" key={indexName}>{item.acceptedCount + ' / ' + (item.employeeCount ? item.employeeCount : 0)}</td>
                 } else if(itemName === 'userCount') {
                   return <td className="catalog-table__name" key={indexName}>{item.userCount ? item.userCount : 0}</td>
                 } else if(itemName === 'employeeCount') {
                    return <td className="catalog-table__name" key={indexName}>{item.employeeCount ? item.employeeCount : 'Всі'}</td>
                 } else if(itemName === 'usersCount') {
                    return <td className="catalog-table__name" key={indexName}>{item.usersCount ? item.usersCount : 'Всі'}</td>
                 } else if(itemName === 'pollsResult') {
                   return <td>
                            {item.progress ? <Link className="questions-table__result" to={this.props.pollsToResult + item[this.props.itemIDName]}>{this.props.pollsToResultTxt}</Link> : <span className="questions-table__result questions-table__result_empty">{this.props.pollsToResultTxt}</span>}
                          </td>
                 } else if(itemName === 'orderNumber') {
                   return <td className="catalog-table__name catalog-table__order-num" key={indexName}>{item[itemName]}</td>
                 } else {
                    return <td className={`catalog-table__name ${this.props.userTable && itemName === 'email' ? 'team-table__email' : ''} ${this.props.userTable && itemName === 'position' ? 'team-table__position' : ''} ${this.props.userTable && itemName === 'team' ? 'team-table__team' : ''} ${this.props.userTable && itemName === 'balance' ? 'team-table__balance' : ''} ${this.props.currencyLabel && this.props.currencyLabel[itemName] ? ' catalog-table__name-hightlight' : ''}`} key={indexName}>{this.props.objData && this.props.objData[itemName] && item[itemName] ? (item[itemName][this.props.objData[itemName]] ? item[itemName][this.props.objData[itemName]] : '') : this.props.libraryStatus && item.bookingUserName && itemName === 'bookingUserName' ? libraryStatus : itemName === 'bookingUserName' ? <LabelStatus name={`${this.props.libraryStatusName}`} theme="green" /> : (this.props.currencyLabel && this.props.currencyLabel[itemName] ? ('0 ' + this.props.currencyLabel[itemName]) : item[itemName])}</td>
                  }
                }
              }
            })}
            {this.props.controlItemsStatus ? <td className="catalog-table__control">
              <div className="data-table__control-inner">
                {(this.props.itemEnableNameForStatus && item[this.props.itemEnableNameForStatusData] === this.props.itemEnableNameForStatus) || (!item[this.props.itemEnableName] && item[this.props.itemIDName] && this.props.itemEnableName) ? <div className="data-table__hide"><svg className="data-table__hide-icon">
                                      <use href={`${sprite}#hide-pass`}></use>
                                    </svg>
                                    <div className="data-table__hide-content">{this.props.enableTooltipMessage}</div>
                                    </div> : ''}
                {item[this.props.itemIDName] ? <div className={`control-wgt${this.props.userTable ? ' control-wgt__pos' : ''}`}>
                  <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                    <svg className="control-btn__icon">
                      <use href={`${sprite}#more-icon`}></use>
                    </svg>
                  </button>
                  <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                    <ul className="control-content__list">
                    {this.props.controlItems.sendCalculation && this.props.controlItems.sendCalculation.status === true ?
                      <li>
                      <button className="control-content__btn" onClick={this.props.controlItems.sendCalculation.handler.bind(this, item[this.props.itemIDName], ((item.name ? item.name + ' ' : '') + (item.middleName ? item.middleName + ' ' : '') + (item.lastName ? item.lastName : '')), 'list')}>
                        <svg className="control-content__icon">
                          <use href={`${sprite}#deposit-icon`}></use>
                        </svg>{this.props.controlItems.sendCalculation.label}</button>
                      </li> : ''}
                    {this.props.controlItems.sendMessage && this.props.controlItems.sendMessage.status === true ?
                      <li>
                      <button className="control-content__btn">
                        <svg className="control-content__icon">
                          <use href={`${sprite}#messages-icon-stroke`}></use>
                        </svg>{this.props.controlItems.sendMessage.label}</button>
                      </li> : ''}
                      {this.props.controlItems.enabled.status === true && !item['companyOwner'] ?
                        <li>
                          <button className="control-content__btn" type="button" onClick={this.props.controlItems.enabled.handler.bind(this, item[this.props.itemIDName], index, item.bookingUserName ? true : false)}>
                            <svg className="control-content__icon">  <use href={`${sprite}${(this.props.itemEnableNameForStatus && (item[this.props.itemEnableNameForStatusData] === this.props.itemEnableNameForStatus)) || (!item[this.props.itemEnableName] && item[this.props.itemIDName] && this.props.itemEnableName) ? '#view-icon-stroke-show' : '#view-icon-stroke'}`}></use></svg>
                            {(this.props.itemEnableNameForStatus && (item[this.props.itemEnableNameForStatusData] === this.props.itemEnableNameForStatus)) || (!item[this.props.itemEnableName] && item[this.props.itemIDName] && this.props.itemEnableName) ? this.props.controlItems.enabled.label.show : this.props.controlItems.enabled.label.hide}
                          </button>
                        </li> : ''}
                        {this.props.controlItems.editButton.status === true ?
                          <li>
                            <button className="control-content__btn" type="button" onClick={this.props.controlItems.editButton.handler.bind(this, index, item[this.props.itemIDName])}>
                              <svg className="control-content__icon">  <use href={`${sprite}#edit-icon`}></use></svg>
                              {this.props.controlItems.editButton.label}
                            </button>
                          </li> : ''}
                          {this.props.controlItems.editLink.status === true ?
                            <li>
                            {this.props.controlItems.editLink.state ? <Link className="control-content__btn" to={this.props.controlItems.editLink.handler + item[this.props.itemIDName]} state={{from: this.props.controlItems.editLink.state}}>
                                                  <svg className="control-content__icon">
                                                    <use href={`${sprite}#edit-icon`}></use>
                                                  </svg>{this.props.controlItems.editLink.label}</Link> : <Link className="control-content__btn" to={this.props.controlItems.editLink.handler + item[this.props.itemIDName]}>
                                                  <svg className="control-content__icon">
                                                    <use href={`${sprite}#edit-icon`}></use>
                                                  </svg>{this.props.controlItems.editLink.label}</Link>}
                            </li> : ''}
                          {this.props.controlItems.delete.status === true ?
                            <li>
                              <button className="control-content__btn control-content__btn_delete" onClick={this.props.controlItems.delete.handler.bind(this, item[this.props.itemIDName], index, item[this.props.bodyDataNames[0]])}>
                                <svg className="control-content__icon">
                                  <use href={`${sprite}#delete-red`}></use>
                                </svg>{this.props.controlItems.delete.label}</button>
                            </li> : ''}
                    </ul>
                  </div>
                </div> : ''}
              </div></td> : ''}
            </>
        </tr>
        })}
      </tbody>
    </table>
  }
}
export default Table;
