import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import Loading from './components/Loading';
import ResultErrors from './components/ResultErrors';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { useParams, Navigate } from "react-router-dom";
import Select from 'react-select';
import Cropper from 'react-easy-crop';
import getCroppedImg from './utils/cropImage';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
let Editor;
let EditorState;
let ContentState;
let convertFromHTML;
let stateToHTML;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;
  stateToHTML = require('draft-js-export-html').stateToHTML;

  require('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
let qw;
if(typeof window !== "undefined" && typeof document !== 'undefined'){
  qw = window.location.protocol;
}
const SortableItem = SortableElement(({item, value, handlerDelete, coverLabel}) => <div><button className="edit-product-img__remove" type="button" onClick={handlerDelete.bind(this, value.photoID)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><span className="product-num">{item + 1}</span>{item === 0 ? <span className="product-label">{coverLabel}</span> : ''}<img className="sort-img" src={qw === 'http:' ? value.url.replace( /https:/g, "http:" ) : value.url} alt="" /></div>);
const SortableList = SortableContainer(({items, handlerDelete, coverLabel}) => {
  return (
    <div className="edit-product-img">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} item={index} index={index} value={value} handlerDelete={handlerDelete} coverLabel={coverLabel} />
      ))}
    </div>
  );
});
class ProductsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      modalAddPhotos: false,
      formData: null,
      formDataForSubmit: null,
      imageSrc: null,
      aspect: 1,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      mainImgName: '',
      cropImageStatus: false,
      imageName: '',
      imageIndex: 0,
      crop: [],
      zoom: [],
      product: {},
      photos: [],
      errors: null,
      error: false,
      isLoading: false,
      categories: [],
      categoriesSelect: [],
      isLoadingSubmit: false,
      formData: null,
      percentValue: 0,
      newMainPhoto: {},
      errorFields: false,
      contentState: '',
      photosFiles: null
    }
    this.onCropChange = this.onCropChange.bind(this);
    this.onZoomChange = this.onZoomChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
  }

  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalDeleteEvents: false,
        modalAddPhotos: false,
        imageSrc: null,
        aspect: 1,
        rotation: 0,
        croppedAreaPixels: null,
        croppedImage: null,
        mainImgName: '',
        cropImageStatus: false,
        imageName: '',
        imageIndex: 0,
        timeoutId: null,
        modalData: {},
        crop: [],
        zoom: [],
        SortableItemsImg: false,
        lengthData: 0,
        photosFiles: null
      }));
    }, 500);
  }

  onCropChange = (crop) => {
    let cropArr = this.state.crop;
    cropArr[this.state.imageIndex] = crop;
    this.setState({ crop: cropArr, cropImageStatus: true })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    let that = this;
    this.setState({ croppedAreaPixels: croppedAreaPixels })
    let croppedImage = '';
    if (croppedAreaPixels && croppedAreaPixels.width >= 325 && croppedAreaPixels.height >= 325) {
      croppedImage = getCroppedImg(
        that.state.imageSrc,
        croppedAreaPixels,
        that.state.rotation
      );
    } else {
      const requiredSize = Math.max(325, croppedAreaPixels.width, croppedAreaPixels.height);
      const scale = 325 / requiredSize;
      const scaledAreaPixels = {
        x: croppedAreaPixels.x * scale,
        y: croppedAreaPixels.y * scale,
        width: croppedAreaPixels.width * scale,
        height: croppedAreaPixels.height * scale,
      };
      croppedImage = getCroppedImg(
        that.state.imageSrc,
        scaledAreaPixels,
        that.state.rotation
      );
    }
     let timeoutId = that.state.timeoutId;
     if(timeoutId) {
      clearTimeout(timeoutId);
     }
     timeoutId = setTimeout(() => {
        const myPromise = new Promise((resolve, reject) => {
          const result = croppedImage;
          resolve(result);
        });
        myPromise.then((result) => {
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
          var img = new Image();
          img.src = result;
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          img.onload = function() {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL('image/jpeg');
            var blob = dataURLToBlob(dataURL);

            const formData = new FormData();
            formData.append('file', blob, that.state.imageName);
            let formDataArr = that.state.formData;
            formDataArr[that.state.imageIndex] = formData.get('file');
            that.setState({
              formData: formDataArr
            });
            function dataURLToBlob(dataURL) {
              var byteString = atob(dataURL.split(',')[1]);
              var mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
              var ab = new ArrayBuffer(byteString.length);
              var ia = new Uint8Array(ab);
              for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }
              return new Blob([ab], {type: mimeString});
            }
          };
        }
        });
     }, 500);
    this.setState({ timeoutId: null })
  }

  onZoomChange = (zoom) => {
    let zoomArr = this.state.zoom;
    zoomArr[this.state.imageIndex] = zoom;
    this.setState({ zoom: zoomArr })
  }

  zoomIn = () => {
    let zoomArr = this.state.zoom;
    zoomArr[this.state.imageIndex] = zoomArr[this.state.imageIndex] + 0.1;
    this.setState((prevState) => ({
      zoom: zoomArr
    }));
  };

  zoomOut = () => {
    let zoomArr = this.state.zoom;
    if(zoomArr[this.state.imageIndex] > 1){
      zoomArr[this.state.imageIndex] = zoomArr[this.state.imageIndex] - 0.1;
      this.setState((prevState) => ({
        zoom: zoomArr
      }));
    }
  };

  onSortEnd = ({oldIndex, newIndex}, event) => {
    this.setState(prevState => ({
      newMainPhoto: newIndex < 1 ? this.state.photos[oldIndex] : this.state.photos[0],
      photos: arrayMoveImmutable(prevState.photos, oldIndex, newIndex)
    }));
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  hanlderRemoveProductImage(id, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    let photos = that.state.photos;
    let index = photos.map(el => el.photoID).indexOf(id);
    fetch(`${that.props.queryLink}/api/v1/products/${that.props.id}/photos/${id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
          });
        })
      } else {
        let photos = that.state.photos;
        let index = photos.map(el => el.photoID).indexOf(id);
        if(photos.length > 1) {
          let nextImg = photos[index].photoID;
          if(nextImg && photos[index].main === true){
              fetch(`${that.props.queryLink}/api/v1/products/${that.props.id}/photos/${nextImg}/main`, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  "main": true,
                  "photoID": nextImg,
                  "url": nextImg.url
                })
              }).then(res => {
                if(res.status !== 200) {
                  res.json().then(function(data) {
                    that.setState({
                      errors: data.errors,
                    });
                  })
                }
              })
          }
        }
        photos.splice(index, 1);
        that.setState(prevState => ({
          prevState: [...prevState.photos, photos]
        }));
      }
    })
  })
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
      document.querySelector('body').style.overflow = "";
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/products/${that.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoading: true
          });
        });
      } else {
        res.json().then(function(data) {
          if(data.photos){
            let photos = data.photos;
            if(photos.length > 1){
              photos.sort(function(x,y){
                return x.main == true ? -1 : y.main == true ? 1 : 0;
              });
            }
          }
          that.setState({
            contentState: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(data.description)
                        )
                      )
          });
          let dataProduct = data;
          dataProduct.description = EditorState.createWithContent(
                      ContentState.createFromBlockArray(
                        convertFromHTML(dataProduct.description)
                      )
                    );
          that.setState({
            product: dataProduct,
            photos: data.photos,
            isLoading: true
          });
          if(data){
            fetch(`${that.props.queryLink}/api/v1/products/categories/search`, {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({
                "itemsPerPage": 0,
                "direction": "DESC",
                "orderField": "productCategoryID"
              })
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors,
                    errorCategories: data.errors
                  });
                });
              } else {
                res.json().then(function(data) {
                  if(data){
                    data.data.forEach((item, i) => {
                      that.setState(prevState => ({
                        categoriesSelect: [...prevState.categoriesSelect, {value: item.categoryID, label: item.name ? item.name : 'Без категорії'}]
                      }));
                    });
                  }
                });
              }
            })
          }
        })
      }
    })
  })
  }
  handlerProductName(event){
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    this.setState(prevState => ({
      product: { ...prevState.product, name: event.target.value}
    }));
  }
  handlerProductPrice(event){
    this.setState(prevState => ({
      product: { ...prevState.product, price: event.target.value}
    }));
  }
  handlerProductStock(event){
    this.setState(prevState => ({
      product: { ...prevState.product, stock: event.target.value}
    }));
  }
  handlerProductLimited(event){
    this.setState(prevState => ({
      product: { ...prevState.product, unlimited: !prevState.product.unlimited}
    }));
  }
  handlerProductDescription(event){
    this.setState(prevState => ({
      product: { ...prevState.product, description: event.target.value}
    }));
  }

  onEditorStateChange = (contentState) => {
    this.setState(prevState => ({
      product: { ...prevState.product, description: contentState},
      contentState: contentState
    }));
  };

  handlerProductCategory(event){
    this.setState(prevState => ({
      product: { ...prevState.product, categoryID: event.value}
    }));
  }
   handlerEditProductSubmit(event){
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState(prevState => ({
      errorFields: false,
      errorNameOnlySpace: false
    }));
    let nameValue = that.state.product.name;

    let onlySpace = /^\s*$/.test(nameValue);
    let itemCorrect;
    if(onlySpace){
      itemCorrect = nameValue;
    } else {
      itemCorrect = nameValue.trim();
    }
    let productObj = this.state.product;
    productObj.name = itemCorrect;
    productObj.description = stateToHTML(productObj.description.getCurrentContent());
    this.setState({ product: productObj });
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    if(error || !productObj.name || !productObj.price || (!productObj.unlimited && !productObj.stock)){
      this.setState({
        errorFields: true
      });
      return false;
    }
    if(!this.state.errorFields){
      this.setState({
        isLoadingSubmit: true
      });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/products/${that.props.id}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(productObj)
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadingSubmit: false,
            errorSubmit: data.errors,
            errors: data.errors
          });
        });
      } else {
        if(!that.state.formData && !that.state.newMainPhoto.photoID){
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            localStorage.setItem("successProductEdit", JSON.stringify(true))
          }
          that.setState({
            isLoadingSubmit: false,
            successSubmit: true
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false
            });
          }, 5000);
        }
        if(that.state.newMainPhoto.photoID){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + that.props.token()
          });
          fetch(`${that.props.queryLink}/api/v1/products/${that.props.id}/photos/${that.state.newMainPhoto.photoID}/main`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "main": true,
              "photoID": that.state.newMainPhoto.photoID,
              "url": that.state.newMainPhoto.url
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              });
            } else {
              if(typeof window !== "undefined" && typeof document !== 'undefined'){
                localStorage.setItem("successProductEdit", JSON.stringify(true));
              }
              that.setState({
                isLoadingSubmit: false,
                successSubmit: true
              });
              setTimeout(() => {
                that.setState({
                  successSubmit: false
                });
              }, 5000);
            }
          })
        }
      }
    })
  })
    }
  }
  handlerCloseSuccess (event) {
    event.preventDefault();
    this.setState(prevState => ({
      successSubmit: false
    }));
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerAddPhotos(event){
    let that = this;
    let files = event.target.files;
    let error = false;
    let photos = that.state.photos ? that.state.photos : [];
    this.setState(prevState => ({
      errorFormatImgs: false
    }));
    if(files){
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileName = file.name.toLowerCase();
          const extension = fileName.split('.').pop();
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
              error = true;
          }
      }
    }
    if(!error){
      if(this.state.photosFiles === null){
        this.setState(prevState => ({
          photosFiles: files
        }))
      }
      let formData = this.state.formData;
      if(this.state.crop && this.state.crop.length > 0){
        this.setState(prevState => ({
          lengthData: (formData.length + prevState.crop.length) + 1
        }))
      }
      if(formData){
          const dt = new DataTransfer();
          if(!this.state.modalAddPhotos){
            for (let file of files) {
              dt.items.add(file)
            }
          }
            this.setState(prevState => ({
              formData: Array.from(dt.files),
              formDataForSubmit: Array.from(dt.files),
              SortableItemsImg: true
            }));

            if(that.state.formData){
              const myHeaders = new Headers({
                'Authorization': 'Bearer ' + that.props.token()
              });
              let formData = new FormData();
              let i = that.state.formData.length;
              let countPercent = 100 / i;
              for (let file of that.state.formData) {
                formData.append('file', file);
                fetch(`${that.props.queryLink}/api/v1/products/${that.props.id}/photos`, {
                  method: 'POST',
                  headers: myHeaders,
                  body: formData
                }).then(res => {
                  if(res.status !== 200) {
                    res.json().then(function(data) {
                      that.setState({
                        errors: data.errors
                      });
                    });
                  } else {
                    res.json().then(function(data) {
                      console.log(data);
                      photos.push(data);
                      i--;
                    if(i < 1){
                        that.setState({
                          photos: photos
                        });
                        that.handleModalDisabled();
                      }
                    });
                  }
                })
                formData.delete('file');
              }
              Promise.all(that.state.formData).then(function() {
                console.log (i);
              });
            };
      } else {
        setTimeout(() => {
          let arrCrop = this.state.crop;
          let arrZoom = this.state.zoom;
            for(let i = 0; i < files.length; i++){
              arrCrop.push({ x: 0, y: 0 });
              arrZoom.push(1);
            }
          this.setState(prevState => ({
            crop: arrCrop,
            zoom: arrZoom
          }));
          this.setState(prevState => ({
            imageSrc: URL.createObjectURL(files[0]),
            imageName: files[0],
            imageIndex: 0
          }));
          this.setState(prevState => ({
            formData: Array.from(files),
            formDataForSubmit: Array.from(files),
            lengthData: (files.length - 1)
          }));
        }, 1000);
      }
      if(this.state.modalAddPhotos){
        this.setState(prevState => ({
          modalAddPhotos: false
        }));
      } else {
        this.setState(prevState => ({
          modalAddPhotos: true
        }));
      }
    } else {
      this.setState(prevState => ({
        errorFormatImgs: true
      }));
    }
  }
  handlerSetFormDataEdit(item, index, event){
    this.setState({
      imageSrc: URL.createObjectURL(item),
      imageName: item.name,
      imageIndex: index,
      aspect: 1,
      rotation: 0,
      croppedAreaPixels: null
    });
  }
  handlerDisabledPhoto(event){
    this.setState({
      imageSrc: null,
      imageName: '',
      imageIndex: null
    });
  }
  handlerDeleteImg(index, event){
    let formData = this.state.formData;
    let formDataForSubmit = this.state.formDataForSubmit;
    let cropData = this.state.crop;
    let zoomData = this.state.zoom;
    formData.splice(index, 1);
    formDataForSubmit.splice(index, 1);
    cropData.splice(index, 1);
    zoomData.splice(index, 1);
    this.setState(prevState => ({
      formData: formData,
      formDataForSubmit: formDataForSubmit,
      crop: cropData,
      zoom: zoomData
    }));
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('catalog-EditItemTitle') + ' | ' + this.props.companyName;
    }
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div>{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
    } else if(!this.state.isLoading) {
      resultContent = <Loading />;
    } else {
      resultContent = <form className="add-product-form" onSubmit={this.handlerEditProductSubmit.bind(this)}>
                  <div className="add-product-form__inner">
                    <div className="edit-user-form__row">
                      <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="name">{this.props.getTranslate('catalog-AddItemName')}</label>
                          <input id="name" className={`field-wrap__input ${this.state.errorFields && (!this.state.product.name || (this.state.errorNameOnlySpace && this.state.product.name !== '')) ? 'field-wrap__input_error' : ''}`} value={this.state.product.name} onChange={this.handlerProductName.bind(this)} placeholder={this.props.getTranslate('catalog-AddItemNameFieldCaption')} />
                          {this.state.errorFields && !this.state.product.name ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                          {this.state.errorFields && this.state.errorNameOnlySpace && this.state.product.name !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                        </div>
                      </div>
                      <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="field-wrap field-wrap__select">
                          <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('catalog-AddItemCategory')}</label>
                          {this.state.categoriesSelect && <Select
                            styles={customStyles}
                            value={this.state.categoriesSelect.find(value => value.value === this.state.product.categoryID)}
                            options={this.state.categoriesSelect}
                            onChange={this.handlerProductCategory.bind(this)}
                            isSearchable={false}
                            placeholder={this.props.getTranslate('select-selectPlaceholder')}
                          />}
                        </div>
                      </div>
                    </div>
                    <div className="edit-user-form__row">
                      <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="price">{this.props.getTranslate('catalog-ItemPrice')}</label>
                          <div className="field-wrap__currency-name">
                            <input id="price" className={`field-wrap__input ${this.state.errorFields && !this.state.product.price ? 'field-wrap__input_error' : ''}`} value={this.state.product.price} onChange={this.handlerProductPrice.bind(this)} placeholder={this.props.getTranslate('catalog-ItemPriceFeildCaption')} />
                            {this.props.currency && <label className="field-wrap__currency-name-item" htmlFor="price">{this.props.currency}</label>}
                          </div>
                          {this.state.errorFields && !this.state.product.price ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                        </div>
                      </div>
                      <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="amount">{this.props.getTranslate('catalog-ItemCount')}</label>
                          {this.state.product.unlimited ? <input id="amount" className="field-wrap__input" value="" disabled/> :
                          <input id="amount" className={`field-wrap__input ${this.state.errorFields && !this.state.product.unlimited && !this.state.product.stock ? 'field-wrap__input_error' : ''}`} value={this.state.product.stock} onChange={this.handlerProductStock.bind(this)} placeholder={this.props.getTranslate('catalog-ItemCountFieldCaption')} />}
                          {this.state.errorFields && !this.state.product.unlimited && !this.state.product.stock ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                        </div>
                      </div>
                      <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="unlimited-product">
                          <div>
                            <input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" checked={this.state.product.unlimited} onChange={this.handlerProductLimited.bind(this)} name="unlimited" />
                            <label htmlFor="styled-checkbox-0"></label>
                          </div>
                          <span>{this.props.getTranslate('catalog-ItemCountUnlimited')}</span>
                        </div>
                      </div>
                    </div>
                    <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="message">{this.props.getTranslate('catalog-ItemDescription')}</label>
                      {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                        toolbar={{
                          options: ['inline', 'blockType', 'list'],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['bold', 'italic', 'underline']
                          },
                          blockType: {
                            inDropdown: true,
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['unordered', 'ordered']
                          }
                        }}
                        wrapperClassName="wrapper-class editor-field"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class editor-toolbar"
                        editorState={this.state.contentState}
                        onEditorStateChange={this.onEditorStateChange}
                      /> : ''}
                    </div>
                    <div className="edit-user-form__imgs">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('catalog-ItemPhoto')}</label>
                      <div className="edit-user-form__imgs-inner">
                        <div className="edit-user-upload">
                          <input type="file" id="upload" multiple onChange={this.handlerAddPhotos.bind(this)} />
                          <label htmlFor="upload">
                          <svg className="upload__icon">
                            <use href={`${sprite}#img-upload`}></use>
                          </svg>
                          {this.props.getTranslate('catalog-ItemPhotoButton')} {this.state.formDataForSubmit && <span style={{marginTop: '5px'}}>{this.props.getTranslate('loadingImg-AmountTitle')} {this.state.photos.length}</span>}</label>
                        </div>
                        {this.state.formDataForSubmit && this.state.isLoadingSubmit ? <div className="edit-user-upload">
                          <div className="edit-user-upload__percent">
                            <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                            <div className="edit-user-upload__bar">
                              <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                            </div>
                          </div>
                        </div> : ''}
                        {this.state.photos ? <SortableList items={this.state.photos} onSortEnd={this.onSortEnd} axis={'xy'} handlerDelete={this.hanlderRemoveProductImage.bind(this)} coverLabel={this.props.getTranslate('catalog-ItemMainPhoto')} /> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="field-wrap__submit add-product-form__submit">
                    <button className="btn btn_width_100 btn_size_lg" type="submit" disabled={this.state.isLoadingSubmit? true : false}>{this.props.getTranslate('catalog-SaveButton')}{this.state.isLoadingSubmit && <Loading />}</button>
                  </div>
                </form>
    }
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink(`/account/products/${this.state.product.categoryID}`)} replace={true} />) : ''}
      <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
      <div className="account-wrap">
        <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
        <div className="account-content">
          <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
            backBtn={true}
            backBtnType="link"
            backBtnClass="button-back_theme_gray"
            backBtnHandler={this.props.getPermalink(`/account/products/${this.state.product.categoryID ? this.state.product.categoryID : 'no-category'}`)}
            backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
          />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('catalog-EditItemTitle')}</h1>
          </div>
              <div className="add-product-form-wrap">
                {resultContent}
              </div>
            </div>
          </div>
      </div>

      {this.state.modalAddPhotos ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal modal__product-img">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('catalog-ModalAddPhotos')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>

        <><div className="modal__img-setting-wrap">
          <div className="modal__img-setting-inner">
            {this.state.imageSrc && this.state.formData && this.state.formData.length > this.state.lengthData ? <Cropper
              image={this.state.imageSrc}
              crop={this.state.crop[this.state.imageIndex]}
              zoom={this.state.zoom[this.state.imageIndex]}
              aspect={this.state.aspect}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
              showGrid={true}
              minZoom={1}
            /> : <Loading />}
            <div className="modal__img-zoom-btns">
              <button className="modal__img-zoom-btn" onClick={this.zoomIn} type="button">+</button>
              <button className="modal__img-zoom-btn" onClick={this.zoomOut} type="button">-</button>
            </div>
          </div>
        </div></>
        {this.state.formDataForSubmit ? <div className="modal__img-row">
            {this.state.formDataForSubmit.map((item, index) => {
              return <div className={`modal__col ${index === this.state.imageIndex ? 'active' : ''}`} key={index}><div className="modal__col-inner"><button className="edit-product-img__remove" type="button" onClick={this.handlerDeleteImg.bind(this, index)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img onClick={this.handlerSetFormDataEdit.bind(this, item, index)} key={index} src={URL.createObjectURL(item)} alt="" /></div></div>
            })}
        </div> : ''}
        <button className="btn btn_size_lg btn_width_100" onClick={this.handlerAddPhotos.bind(this)} type="button">Завантажити</button>
      </div>
      </div> : ''}

    </>
  }
}
export default (props) => <ProductsEdit {...useParams()} {...props} />
