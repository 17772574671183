import React from "react";
import { Link } from "react-router-dom";
import sprite from '../media/icons.svg';
import google_play from '../media/google-play-btn.png';
import apple_store from '../media/apple-store-btn.png';
import footer_logo from '../media/footer-logo.svg';
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openWgt: null
    }
  }
  handlerOpenFooterWgt(item, event){
    this.setState({
      openWgt: item
    });
  }
  render() {
    return <footer className={this.props.footerAccount ? 'footer footer__account' : `footer ${this.props.fixed ? 'footer_fixed' : ''}`}>
      <div className="wrapper">
        <div className="footer__inner">
          <div className="footer-wgt">
            <div className="footer-wgt__headline-mob" onClick={this.handlerOpenFooterWgt.bind(this, 'opport')}>{this.props.getTranslate('footer-OpportunitiesTitle')}
            <svg className={`footer-wgt__icon-down${this.state.openWgt === 'opport' ? ' active' : ''}`}>
              <use href={`${sprite}#arrow-down`}></use>
            </svg>
            </div>
            <div className={`footer-wgt__inner${this.state.openWgt === 'opport' ? ' active' : ''}`}>
              <div className="footer-wgt__headline">{this.props.getTranslate('footer-OpportunitiesTitle')}</div>
              <ul className="footer-wgt-menu">
                <li className="footer-wgt-menu__item">
                  <Link className="footer-wgt-menu__link" to={this.props.getPermalink('/about')}>{this.props.getTranslate('footer-OpportunitiesMenu')}</Link>
                </li>
                <li className="footer-wgt-menu__item">
                  <Link className="footer-wgt-menu__link" to={this.props.getPermalink('/about-panel')}>{this.props.getTranslate('footer-ManagementMenu')}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-wgt">
            <div className="footer-wgt__headline-mob" onClick={this.handlerOpenFooterWgt.bind(this, 'about')}>{this.props.getTranslate('footer-AboutTitle')}
            <svg className={`footer-wgt__icon-down${this.state.openWgt === 'about' ? ' active' : ''}`}>
              <use href={`${sprite}#arrow-down`}></use>
            </svg>
            </div>
            <div className={`footer-wgt__inner${this.state.openWgt === 'about' ? ' active' : ''}`}>
              <div className="footer-wgt__headline">{this.props.getTranslate('footer-AboutTitle')}</div>
              <ul className="footer-wgt-menu">
                <li className="footer-wgt-menu__item">
                  <Link className="footer-wgt-menu__link" to={this.props.getPermalink('/contacts')}>{this.props.getTranslate('footer-ContactsMenu')}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-wgt">
            <div className="footer-wgt__headline-mob" onClick={this.handlerOpenFooterWgt.bind(this, 'users')}>{this.props.getTranslate('footer-ToUsersTitle')}
            <svg className={`footer-wgt__icon-down${this.state.openWgt === 'users' ? ' active' : ''}`}>
              <use href={`${sprite}#arrow-down`}></use>
            </svg>
            </div>
            <div className={`footer-wgt__inner${this.state.openWgt === 'users' ? ' active' : ''}`}>
              <div className="footer-wgt__headline">{this.props.getTranslate('footer-ToUsersTitle')}</div>
              <ul className="footer-wgt-menu">
                <li className="footer-wgt-menu__item">
                  <Link className="footer-wgt-menu__link" to={this.props.getPermalink('/terms')}>{this.props.getTranslate('footer-OfferMenu')}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-wgt footer-wgt__apps">
          <div className="footer-wgt__headline-mob" onClick={this.handlerOpenFooterWgt.bind(this, 'app')}>{this.props.getTranslate('footer-AppTitle')}
            <svg className={`footer-wgt__icon-down${this.state.openWgt === 'app' ? ' active' : ''}`}>
              <use href={`${sprite}#arrow-down`}></use>
            </svg>
          </div>
            <div className={`footer-wgt__inner${this.state.openWgt === 'app' ? ' active' : ''}`}>
              <div className="footer-wgt__headline">{this.props.getTranslate('footer-AppTitle')}</div>
              <div className="footer-wgt-apps">
                <div className="footer-wgt-apps__inner">
                  <div className="footer-wgt-apps__item">
                    <a href="https://apps.apple.com/ua/app/matespace-io/id1609868472" target="_blank">
                      <img className="footer-wgt-apps__img" src={apple_store} alt="" />
                    </a>
                  </div>
                  <div className="footer-wgt-apps__item">
                    <a href="https://play.google.com/store/apps/details?id=com.matespace&hl=en_US" target="_blank">
                      <img className="footer-wgt-apps__img" src={google_play} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-wgt-apps__inner footer-wgt-apps__inner-mob">
        <div className="footer-wgt-apps__item">
          <a href="https://apps.apple.com/ua/app/matespace-io/id1609868472" target="_blank">
            <img className="footer-wgt-apps__img" src={apple_store} alt="" />
          </a>
        </div>
        <div className="footer-wgt-apps__item">
          <a href="https://play.google.com/store/apps/details?id=com.matespace&hl=en_US" target="_blank">
            <img className="footer-wgt-apps__img" src={google_play} alt="" />
          </a>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="wrapper">
          <div className="footer__bottom-inner">
            <div className="footer__bottom-wgt">
              <div className="footer__bottom-wgt-inner">
                <div className="footer__bottom-wgt-copy">© 2025 matespace.io</div>
              </div>
            </div>
            <div className="footer__bottom-wgt footer__bottom-wgt__logo">
              <div className="footer__bottom-wgt-inner">
                <img className="footer__bottom-wgt__logo-img" src={footer_logo} alt="matespace.io" />
              </div>
            </div>
            <div className="footer__bottom-wgt footer__bottom-wgt__socials">
              <ul className="footer-socials">
                <li className="footer-socials__item">
                  <a className="footer-socials__link" href="#">
                    <svg className="footer-socials__icon">
                      <use href={`${sprite}#linkedin-icon`}></use>
                    </svg>
                  </a>
                </li>
                <li className="footer-socials__item">
                  <a className="footer-socials__link" href="#">
                    <svg className="footer-socials__icon footer-socials__icon--youtube">
                      <use href={`${sprite}#youtube-icon`}></use>
                    </svg>
                  </a>
                </li>
                <li className="footer-socials__item">
                  <a className="footer-socials__link" href="#">
                    <svg className="footer-socials__icon footer-socials__icon--facebook">
                      <use href={`${sprite}#facebook-icon`}></use>
                    </svg>
                  </a>
                </li>
                <li className="footer-socials__item">
                  <a className="footer-socials__link" href="#">
                    <svg className="footer-socials__icon footer-socials__icon--instagram">
                      <use href={`${sprite}#instagram-icon`}></use>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  }
}
export default Footer;
